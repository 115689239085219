
  export const style={
    inpuStyle:{
      colorInput:"#285879"
    },
    url:{
      urlClient:"https://petro-rio.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo_prio.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: false,
    },
    CurrencyName:{
      name:"brents/brents"
    },
    teamName:{
      name:"PetroRio"
    },
    hasToken: true,
    hostHeader: "petro-rio"
  }
